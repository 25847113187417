import React, { useState } from 'react';
import Footer from './Footer';
import aboutImage from './about.png';

const ContactUs = () => {
  const [isMessageVisible, setMessageVisible] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: ''
  });

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // Perform any necessary form submission or data processing here
    // For example, you can log the form data to the console
    console.log('Form Data:', formData);
    // Display the success message
    setMessageVisible(true);

    // Reset the form data after submission if needed
    setFormData({
      name: '',
      lastName: '',
      email: '',
      phone: ''
    });

    // You can also perform any API calls or other actions here
  };
  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }
  return (
    <>

<div className="flex flex-col md:flex-row">
  <div className="lg:w-1/2 bg-[#ffffff] p-6 flex flex-col justify-center cursor-pointer">
    <h1 className="text-3xl font-semibold mb-4 text-[#000000]">Contactez-nous pour planifier une visite de votre nouvelle maison.</h1>
    <h5 className="px-3 my-6 ">Veuillez remplir ce formulaire avec vos coordonnées concernant vos demandes, et nous vous répondrons rapidement</h5>
    <div className="flex items-center mb-2 gap-2 ">
      <i className="fa-solid fa-phone"></i>
      <span>+123 456 789</span>
    </div>
    <div className="flex items-center mb-2 gap-2 ">
      <i className="fa-solid fa-envelope"></i>
      <span>info@example.com</span>
    </div>
    <div className="flex items-center mb-2 gap-2 ">
      <i className="fa-solid fa-location-dot"></i>
      <span>Bouskoura, Casablanca</span>
    </div>
    <div className="flex items-center gap-2 ">
      <i className="fa-solid fa-clock"></i>
      <span>Lun - Ven: 9am - 5pm</span>
    </div>
  </div>

  <div className="lg:w-1/2 lg:order-first bg-[#ffffff] p-4">
    <h2 className="mb-4 text-2xl font-semibold">DEMANDE D'INFORMATIONS</h2>
    <form className="h-3/4 relative "  onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Nom
            </label>
            <input type="text" id="name" name="name" className="mt-1 p-2 border rounded w-full" placeholder='Votre Nom*'             value={formData.name}
            onChange={handleChange}
 required />
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Prénom
            </label>
            <input 
                        value={formData.lastName}
                        onChange={handleChange}
            
            type="text" id="lastName" name="lastName" className="mt-1 p-2 border rounded w-full" placeholder='Votre Prénom*'required />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input             value={formData.email}
            onChange={handleChange}
type="email" id="email" name="email" className="mt-1 p-2 border rounded w-full"  placeholder='Adress Email*'required />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              Numéro de téléphone
            </label>
            <input            value={formData.phone}
            onChange={handleChange}
 type="tel" id="phone" name="phone" className="mt-1 p-2 border rounded w-full" placeholder='Téléphone*' required/>
          </div>
        
          <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 w-full"  onClick={handleSubmit}>
            Envoyer
          </button>
          {isMessageVisible && (
        <p className="text-center text-[#40549D] mt-2">Un responsable vous contactera dans moins de 24 heures.</p>
      )}
          </form>
  </div>
</div>

<div className="lg:flex lg:w-full">
  <div className="lg:w-full p-4">
  
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d106530.35154852575!2d-7.733109686400677!3d33.447391939640454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62e48d1b55623%3A0xde48e8e519fbeb3d!2sBouskoura!5e0!3m2!1sfr!2sma!4v1693310128351!5m2!1sfr!2sma"
      width="100%"
      height="400"
      
      allowFullScreen={true}
      loading="lazy"
    ></iframe>
  </div>
</div>

      <Footer />
    </>
  );
};

export default ContactUs;
