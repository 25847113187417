import React from 'react';

const MaintenanceMessage = () => {
  return (
    <div className="flex flex-col justify-center items-center h-96">
    <h2 className="text-2xl text-encours-color m-5 p-5">
      En cours de maintenance...
    </h2>
  </div>
  );
};

export default MaintenanceMessage;
