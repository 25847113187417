import React from 'react';
import { Link } from 'react-router-dom';
import img1 from "../Images/image1.webp";
import img2 from "../Images/image2.webp";
import img3 from "../Images/image3.webp";
import img4 from "../Images/image4.webp";
import img5 from "../Images/image5.webp";
import img6 from "../Images/image6.webp";


const YourComponent = () => {
  return (
    <div>
     <div className="bg-[#40549D] h-[200px] flex items-center justify-center"><h3 className="text-center mb-3 text-[#ffffff] font-bold px-6">
        CONÇUS MÉTICULEUSEMENT POUR INSUFFLER UNE LUMIÈRE AMBIANTE, LES APPARTEMENTS DES RÉSIDENCES LOUISE INCARNENT L'ALLIANCE PARFAITE ENTRE RAFFINEMENT ET BIEN-ÊTRE.
      </h3></div>
      <div className=" mx-auto px-4 md:px-8 lg:px-16 xl:px-20 bg-[#F4F4F4] ">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 container">
  <div className="relative overflow-hidden">
    <img
      src={img1}
      alt=""
      className="w-full h-auto transition-opacity duration-300"
    />
    <div className="absolute inset-0 bg-blue-500 opacity-0 hover:opacity-50 transition-opacity duration-300"></div>
  </div>
  
  <div className="relative overflow-hidden">
    <img
      src={img2}
      alt="Image 2"
      className="w-full h-auto transition-opacity duration-300"
    />
    <div className="absolute inset-0 bg-[#F8B134] opacity-0 hover:opacity-50 transition-opacity duration-300"></div>
  </div>
  
  <div className="relative overflow-hidden">
    <img
      src={img3}
      alt=""
      className="w-full h-auto transition-opacity duration-300"
    />
    <div className="absolute inset-0 bg-blue-500 opacity-0 hover:opacity-50 transition-opacity duration-300"></div>
  </div>
  
  <div className="relative overflow-hidden">
    <img
      src={img4}
    
      alt=""
      className="w-full h-auto transition-opacity duration-300"
    />
    <div className="absolute inset-0 bg-[#F8B134] opacity-0 hover:opacity-50 transition-opacity duration-300"></div>
  </div>
  
  <div className="relative overflow-hidden">
    <img
      src={img5}
      alt=""
      className="w-full h-auto transition-opacity duration-300"
    />
    <div className="absolute inset-0 bg-blue-500 opacity-0 hover:opacity-50 transition-opacity duration-300"></div>
  </div>
  
  <div className="relative overflow-hidden">
    <img
      src={img6}
      alt=""
      className="w-full h-auto transition-opacity duration-300"
    />
    <div className="absolute inset-0 bg-[#F8B134] opacity-0 hover:opacity-50 transition-opacity duration-300"></div>
  </div>
</div>

    </div>
    <div className="flex justify-center items-center my-5">
  <Link to="/encours" className="">
    <button className="transition duration-300 ease-in-out text-[#ffffff] transform hover:scale-105 bg-[#40549D] hover:text-white mx-6 py-2 px-4  border border-[#40549D] rounded-md shadow-md hover:shadow-lg flex items-center space-x-2">
      <span>Appartement témoin</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none" className="text-[#40549D] hover:text-white">
        <path d="M10.5 8.5H23.5M23.5 8.5V21.5M23.5 8.5L8.5 23.5" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  </Link>
</div>
    </div>
  );
}

export default YourComponent;
