import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Galerie from './Galerie';

function Services(){
    const data=[
        {
            title:"Clarté",
            description:" Notre objectif est de vous fournir des informations détaillées à chaque étape de votre parcours immobilier.",
            icon:"fa-solid fa-eye"
        },
        {
            title:"Qualité",
            description:"Nous nous engageons fermement envers la qualité dans chaque aspect de notre travail.",
            icon:"fa-solid fa-medal"
        },
        {
            title:"Service Client",
            description:"Notre équipe dévouée est là pour vous guider, vous conseiller et répondre à vos questions.",
            icon:"fa-solid fa-handshake"
        },
        {
            title:"Communication",
            description:"Nous nous engageons à vous tenir informé à chaque étape du processus, à vous fournir des mises à jour régulières.",
            icon:"fa-regular fa-comments"
        }
    ]
    return(
        <> 
           
           <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 p-8">
  {data.map((feature, index) => (
    <div key={index} className="flex-shrink-0 w-full sm:w-1/2 md:w-1/4">
      <div className="bg-white shadow-md p-6 rounded-lg transform hover:scale-105 transition duration-300 h-full flex flex-col justify-between">
      <div className="flex items-center justify-center h-12 w-12 bg-[#40549D] text-white rounded-full mx-auto mb-4">
          <i className={`fas ${feature.icon} text-lg`} />
        </div>
        <h3 className="mt-4 text-xl font-semibold text-gray-900 text-center">{feature.title}</h3>
        <p className="mt-2 text-gray-600 text-sm">{feature.description}</p>
      </div>
    </div>
  ))}
</div>
        
      
     

   
    
    
<Galerie />
   
</>
      
    );
}

export default Services;