import React, { useState } from 'react';
import logo from "../Images/loggoo.jpeg";

import { Link } from 'react-router-dom';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleEmailChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsSubscribed(true);
    setEmail('');
  };

  return (
    <><footer className=" py-8 text-[#ffffff] bg-[#192e67]">
      
    <div className="mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-4 ">
    <div className="md:col-span-1 lg:col-span-1">
      <img className="w-24" src={logo} alt="Logo" />
    </div>
    <div className="md:col-span-1 lg:col-span-1">
      <ul className="">
        <li className="my-3">
          Le meilleur choix pour votre prochaine acquisition
        </li>
      </ul>
    </div>
    <div className="md:col-span-1 lg:col-span-1">
      <h2 className="mb-6 text-sm font-semibold  uppercase">
        Suivez-nous
      </h2>
      <ul className="">
        <li className="mb-3">
          <a href="#" className="hover:underline">
            <i className="fab fa-facebook"></i> Facebook
          </a>
        </li>
        <li className="mb-3">
          <a href="#" className="hover:underline">
            <i className="fab fa-instagram"></i> Instagram
          </a>
        </li>
        <li className="mb-3">
          <a href="#" className="hover:underline">
            <i className="fab fa-google"></i> Google
          </a>
        </li>
      </ul>
    </div>
    <div className="md:col-span-1 lg:col-span-1">
      <h2 className="mb-6 text-sm font-semibold  uppercase">
        Inscrivez-vous pour recevoir les dernières nouvelles
      </h2>
      <div onSubmit={handleSubscribe} className="flex flex-col space-y-3">
        
        <button
          type="submit"
          className="bg-[#40549D] text-white px-4 py-2 my-3 rounded hover:bg-blue-600 transition duration-300"
        >
          <Link to="/contact" >Contacter nous</Link>
        </button>
      </div>
      {isSubscribed && (
        <div className="text-blue-500 my-3">
          Un responsable vous contactera dans moins de 24H
        </div>
      )}
    </div>
  </div>
  
</footer>
<div className="mx-auto px-4 py-6 text-center text-gray-200 text-[#c6acaf]">
    <span className="text-sm hover:text-[#F8B134]" >
      © 2023 DIAR AL GHALIA. <a href="https://startjobs.ma/">All Rights Reserved.</a>
    </span>
  </div>
    </>
    

  );
};

export default Footer;
