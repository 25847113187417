import React, { useState, useRef, useEffect } from 'react';
import logo from "../Images/loggoo.jpeg";

import { Link } from 'react-router-dom';


function Navbar() {
  const [scrollHeight, setScrollHeight] = useState(0);
const [navHeight, setNavHeight] = useState('120px'); // Initial height

useEffect(() => {
  const handleScroll = () => {
    setScrollHeight(window.scrollY);
  };

  // Add event listener when the component mounts
  window.addEventListener('scroll', handleScroll);

  // Remove event listener when the component unmounts
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

useEffect(() => {
  // Set navHeight with a smooth animation
  setNavHeight(scrollHeight > 100 ? '100px' : '120px');
}, [scrollHeight]);
  const [open, setOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [language, setLanguage] = useState('french'); 
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  
  const closeNavbar = () => {
    setOpen(false);
    setIsDropdownOpen(false);
  };

  return (
    <nav className='sticky top-0 bg-[#192e67] h-21 z-50 ' style={{ height: navHeight }}>
      <div className=' flex items-center font-medium justify-around '>
      <div className='z-50 p-6 md:w-auto w-full flex items-center justify-between '>
        <Link to="/project22"> <img src={logo} alt="logo" className="md:cursor-pointer h-16 rounded-full w-auto max-w-full "/></Link>
  

  <div className='text-3xl md:hidden' onClick={() => setOpen(!open)}>
    {open ? (
      <>
        <i className="fa-solid fa-xmark text-[#F8B134]"></i>
      </>
    ) : (
      <i className="fa-solid fa-bars text-[#F8B134]"></i>
    )}
  </div>
</div>


        <ul className=' md:flex hidden cursor-pointer flex items-center gap-8 text-xs '>
          <li className='py-7 px-3 inline-block text-gray-800 hover:text-[#ffffff] text-[#ffffff] hover:border-b-2 border-[#F8B134] transition duration-300 ease-in-out transform hover:scale-110'>
            <Link to="/" className='hover:text-[#ffffff] hover:border-[#ffffff] text-[#ffffff]'> Acceuil</Link>
          </li>
          <li className='py-7 px-3 inline-block text-gray-800 hover:text-[#ffffff] text-[#ffffff] hover:border-b-2 border-[#F8B134] transition duration-300 ease-in-out transform hover:scale-110'>
            <button className='flex items-center space-x-2 focus:outline-none' onClick={toggleDropdown}>
            <Link to='/aboutus'className='hover:text-[#ffffff] hover:border-[#ffffff] text-[#ffffff]' >A propos de nous</Link>
              
            </button>
          
          </li>
          <li className='py-7 px-3 inline-block text-gray-800 hover:text-[#ffffff] text-[#ffffff] hover:border-b-2 border-[#F8B134] transition duration-300 ease-in-out transform hover:scale-110'>
            <Link to="/proposition" className='hover:text-[#ffffff] hover:border-[#ffffff] text-[#ffffff]'> Nos Appartement</Link>
          </li>
          <li className='py-7 px-3 inline-block text-gray-800 hover:text-[#ffffff] text-[#ffffff] hover:border-b-2 border-[#F8B134] transition duration-300 ease-in-out transform hover:scale-110'>
             <Link to="/galerie" className='hover:text-[#ffffff] hover:border-[#ffffff] text-[#ffffff]'>Galerie</Link>
          </li>
        </ul>
        <div className='md:flex hidden'>
          
          <div className='ml-4 flex items-center'>
            
            
          </div>
          <button className='bg-[#40549D] hover:text-[#ffffff] hover:border-b-2 border-[#40549D] text-[#FFFFFF] mx-6 text-xs text-white py-2 px-6 rounded-md '>
             <Link className='hover:text-[#ffffff] ' to="/contact">CONTACTER NOUS</Link>
          </button>
        </div>
      </div>

      <ul   className={`cursor-pointer
        md:hidden bg-[#192e67] text-[#005BAF] fixed w-full top-0 overflow-y-auto my-4 bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%] "}
        `}>                
                    <li className='py-7 px-3  '> <Link to="/" onClick={closeNavbar} className='hover:text-[#ffffff] hover:border-[#ffffff] text-[#ffffff]'> Acceuil</Link></li>
                    
                    <li className='py-7 px-3  '>
                    <Link to='/aboutus' onClick={closeNavbar} className='hover:text-[#ffffff] hover:border-[#ffffff] text-[#ffffff]'>A propos de nous</Link>

          </li>

                    <li className='py-7 px-3 hover:text-blue-500'><Link to="/proposition" onClick={closeNavbar} className='hover:text-[#ffffff] hover:border-[#ffffff] text-[#ffffff]'> Nos Appartement</Link>
 </li>
                    <li className='py-7 px-3 hover:text-blue-500'><Link to="/galerie" onClick={closeNavbar} className='hover:text-[#ffffff] hover:border-[#ffffff] text-[#ffffff]'>Galerie</Link>

</li> 
                    
                    <div className='py-5 '>
              <button className='bg-[#005BAF] text-[#FFFFFF] font-bold py-2 px-6 rounded 'onClick={closeNavbar} >
             <Link to="/contact">CONTACTER NOUS</Link>
          </button>
           </div>
              </ul>
    </nav>
  );
}

export default Navbar;
