import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Selection from '../Selection';


function Proposition() {
  const propertyData = [
    {
      imageSrc: 'https://images.unsplash.com/photo-1614628079765-6c164f4bd970?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      title: 'Appartement avec un atmosphère convivale',
      price: 'Prix: 5 700 000 Dhs',
      location: 'Casablanca - Centre Ville',
      reference: 'Ref:668VA',
    },

    {
      imageSrc: 'https://images.unsplash.com/photo-1592222376988-92af20d4d06c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      title: 'Appartement moderne avec une bonne vue',
      price: 'Prix: 3 700 000 Dhs',
      location: 'Casablanca - hay hassani',
      reference: 'Ref:627VA',
    },
    {
      imageSrc: 'https://images.unsplash.com/photo-1613082442324-62ef5249275e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      title: 'Appartement confortable et bien situé',
      price: 'Prix: 4 700 000 Dhs',
      location: 'Casablanca - Centre Ville',
      reference: 'Ref:637VA',
    },
    {
      imageSrc: 'https://images.unsplash.com/photo-1614628079765-6c164f4bd970?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      title: 'Appartement avec un atmosphère convivale',
      price: 'Prix: 5 700 000 Dhs',
      location: 'Casablanca - Centre Ville',
      reference: 'Ref:668VA',
    },
  ];
  const garages=[
    {
      image:"https://cdn-immonot.notariat.services/vignette-750/jpg/25412052_2_print/location-garage-et-parking-saint-vigor-le-grand-14400-calvados-858-euros-L571-001.jpg",
      title:"Espaces de Stationnement Sécurisés Disponibles à la Location"
    
    },
    {
      image:"https://cdn-immonot.notariat.services/vignette-750/jpg/25412052_3_print/location-garage-et-parking-saint-vigor-le-grand-14400-calvados-858-euros-L571-002.jpg",
      title:"Location de Garages : Votre Solution de Stationnement "
    },
    {
      image:"https://imagesv2.fnaim.fr/images1/img/649f418926789f4f863e61b3.jpg",
      title:"Espaces de Stationnement Disponibles pour Location"
    },
    {
      image:"https://imganuncios.mitula.net/local_dactivites_entrepot_38660_3100098690982075013.jpg",
      title:"Location de Garages : Location de Garages Pratiques et Accessibles"
    }
  ]

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    },
  };
  const [selectedOption, setSelectedOption] = useState('appartements');
  const options = ['appartements', 'garages'];

  const handleOptionChange = (option: React.SetStateAction<string>) => {
    setSelectedOption(option);
  };



  return (

   <>
       <div>
       <div className="flex space-x-4 mb-4 items-center justify-center cursor-pointer">

{options.map((option) => (
  <h1
  key={option}
  className={`px-4 py-2 ${selectedOption === option ? 'text-[#40549D] border-b-2 border-[#40549D]' : 'text-gray-700'} hover:text-[#40549D] focus:text-[#40549D] focus:outline-none`}
  onClick={() => handleOptionChange(option)}
>
  {option === 'appartements' ? 'Appartements' : 'Garages'}
</h1>
))}
</div>

  {selectedOption === 'appartements' ? (
    <div className="" id="proposition">
      <h2 className="text-3xl font-semibold mb-6 text-center text-[#40549D]">Explorez Nos Appartements</h2>
      <Carousel responsive={responsive} swipeable={false} className="z-30 cursor-pointer">
        {propertyData.map((property, index) => (
          <div key={index} className="card my-6 mx-3 text-center cursor-pointer border rounded-lg overflow-hidden hover:shadow-lg transition duration-300 transform hover:-translate-y-2">
            <img className="product--image w-full h-48 object-cover rounded-t-lg" src={property.imageSrc} alt={property.title} />
            <div className="bg-white p-4 flex flex-col justify-between rounded-b-lg">
              <div>
                <h2 className="text-xl font-semibold text-[#40549D] mb-2">{property.title}</h2>
              </div>
              
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  ) : (
    <div className="text-center">
      <h2 className="text-3xl font-semibold mb-6 text-[#40549D]">Explorez Nos Garages</h2>
      <Carousel responsive={responsive} className="">
      {garages.map((property, index) => (
          <div key={index} className="card my-6 mx-3 text-center cursor-pointer border rounded-lg overflow-hidden hover:shadow-lg transition duration-300 transform hover:-translate-y-2">
            <img className="product--image w-full h-48 object-cover rounded-t-lg" src={property.image} alt={property.title} />
            <div className="bg-white p-4 flex flex-col justify-between rounded-b-lg">
              <div>
                <h2 className="text-xl font-semibold text-[#40549D] mb-2">{property.title}</h2>
              </div>
              
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )}
</div>
<Footer />   
   </>

    



  );
}

export default Proposition;
