import React, { useState } from 'react';
import Valeurs from './Valeurs';
import Footer from './Footer';
import aboutImage from "../Images/apreopos.webp";
const AboutUs = () => {
  const [expanded, setExpanded] = useState(false);

 




  return (
    <>
    <div className="bg-[#40549D] h-[200px] flex items-center justify-center"><h3 className="text-center mb-3 text-[#ffffff] font-bold px-6">
          CONÇUS MÉTICULEUSEMENT POUR INSUFFLER UNE LUMIÈRE AMBIANTE, LES APPARTEMENTS DES RÉSIDENCES LOUISE INCARNENT L'ALLIANCE PARFAITE ENTRE RAFFINEMENT ET BIEN-ÊTRE.
        </h3></div>
    
   

    

        <div className="p-6 bg-white shadow-md rounded-md flex flex-col md:flex-row">
  <div className="md:flex-none md:w-1/3 md:pr-6">
    <img src={aboutImage} />
  </div>
  <div className="flex-grow">
    <div className="text-gray-700 px-6 py-2">
      <p className="mb-4">
        Bienvenue chez Diar Al Ghalia, l'agence immobilière de référence à Casablanca, dédiée à vous offrir des services immobiliers exceptionnels avec un engagement envers l'excellence et la satisfaction client.
      </p>
      <p className="mb-4">
        Si vous cherchez à acheter votre appartement ou votre garage de commerce à Casablanca, nous sommes là pour vous offrir les meilleurs choix, et vous guider à chaque étape du processus avec notre expertise, et notre connaissance approfondie du marché immobilier local.
      </p>
      <p className="mb-4">
        Chez Diar Al Ghalia, nous comprenons que chaque individu et chaque famille a des besoins et des préférences uniques en matière de logement. C'est pourquoi notre portefeuille immobilier soigneusement conçu offre une variété d'options.
      </p>
      <p>
        Diar Al Ghalia est bien plus qu'une simple agence immobilière, c'est une équipe passionnée et dévouée d'experts en immobilier qui se consacrent à réaliser vos rêves de propriété. Notre entreprise est idéalement située au cœur de Casablanca, ce qui nous permet de servir efficacement les besoins immobiliers variés de nos clients dans toute la ville et ses environs.
      </p>
    </div>
    <div className="flex mt-4">
        <div className="flex-grow text-center">
          <i className="fa-solid fa-eye text-3xl text-[#40549D]"></i>
          <p className="mt-1 text-sm text-[#40549D]">Clarté</p>
        </div>
        <div className="flex-grow text-center">
          <i className="fa-solid fa-medal text-3xl text-[#40549D]"></i>
          <p className="mt-1 text-sm text-[#40549D]">Qualité</p>
        </div>
        <div className="flex-grow text-center">
          <i className="fa-solid fa-handshake text-3xl text-[#40549D]"></i>
          <p className="mt-1 text-sm text-[#40549D]">Service client</p>
        </div>
        <div className="flex-grow text-center">
          <i className="fa-regular fa-comments text-3xl text-[#40549D]"></i>
          <p className="mt-1 text-sm text-[#40549D]">Communiction</p>
        </div> </div>
  </div>
</div>

   

    <Footer />
  </>
    
  );
};

export default AboutUs;
