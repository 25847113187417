import React, { useState } from 'react';
import bagg from '../Images/backhome.webp';
import Footer from './Footer';
import about from "../Images/about.png"
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Valeurs from './Valeurs';
import cta from "../Images/cta.png";
import aboutimg from "../Images/about.webp";




function HomePage() {
  
  const propertyData = [
    {
      imageSrc: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/292876092.jpg?k=5058631fdf8ef2f978b50c31d28094c59ccd6eb172295db4ab462ab9426b5275&o=&hp=1',
      title: 'Appartement avec un atmosphère convivale',
      price: 'Prix: 5 700 000 Dhs',
      location: 'Casablanca - Centre Ville',
      reference: 'Ref:668VA',
    },

    {
      imageSrc: 'https://venteappartementcasablanca.files.wordpress.com/2016/05/31.jpg',
      title: 'Appartement moderne avec une bonne vue',
      price: 'Prix: 3 700 000 Dhs',
      location: 'Casablanca - hay hassani',
      reference: 'Ref:627VA',
    },
    {
      imageSrc: 'https://cf.bstatic.com/xdata/images/hotel/max1280x900/417018490.jpg?k=7c524c95326b5a9b907e6eb394f45d547238d89666be84c5ce62d6b2b5ffa705&o=&hp=1',
      title: 'Appartement confortable et bien situé une bonne vue',
      price: 'Prix: 4 700 000 Dhs',
      location: 'Casablanca - Centre Ville',
      reference: 'Ref:637VA',
    },
    {
      imageSrc: 'https://spotit.ma/wp-content/uploads/2021/02/appartement-neuf-2-chambres-a-vendre-a-oulfa-casablanca-maroc-2.png',
      title: 'Appartement avec un atmosphère convivale',
      price: 'Prix: 5 700 000 Dhs',
      location: 'Casablanca - Centre Ville',
      reference: 'Ref:668VA',
    },
  ];
  const garages=[
    {
      image:"https://cdn-immonot.notariat.services/vignette-750/jpg/25412052_2_print/location-garage-et-parking-saint-vigor-le-grand-14400-calvados-858-euros-L571-001.jpg",
      title:"Espaces de Stationnement Sécurisés Disponibles à la Location"
    
    },
    {
      image:"https://cdn-immonot.notariat.services/vignette-750/jpg/25412052_3_print/location-garage-et-parking-saint-vigor-le-grand-14400-calvados-858-euros-L571-002.jpg",
      title:"Location de Garages : Votre Solution de Stationnement "
    },
    {
      image:"https://imagesv2.fnaim.fr/images1/img/649f418926789f4f863e61b3.jpg",
      title:"Espaces de Stationnement Disponibles pour Location"
    },
    {
      image:"https://imganuncios.mitula.net/local_dactivites_entrepot_38660_3100098690982075013.jpg",
      title:"Location de Garages : Location de Garages Pratiques et Accessibles"
    }
  ]

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    },
  };
  const [selectedOption, setSelectedOption] = useState('appartements');
  const options = ['appartements', 'garages'];

  const handleOptionChange = (option: React.SetStateAction<string>) => {
    setSelectedOption(option);
  };
  return (
    <>
    <div className="bg-cover bg-center h-[500px] flex items-center justify-center" style={{ backgroundImage: `url(${bagg})` }}>

      <div className="text-center text-white text-[#ffffff]">
        <h1 className="text-4xl md:text-6xl font-bold mb-6 px-5 text-[#ffffff] hometitle">Trouvons ensemble votre meilleure acquisition</h1>
        <p className="text-lg md:text-xl mb-8  text-[#ffffff]">Découvrez des Maisons Écologiques avec Notre Agence Immobilière.</p>
        <div className="max-w-md mx-auto px-4">
        

        </div>
      </div>
      
    </div>
    <div className='inline flex items-center justify-center  '>
      <hr></hr>
      <h3 className='text-[#40549D]'>A props de nous</h3>
    </div>
   
    <div className="about flex flex-col-reverse md:flex-row items-center gap-6 text-[#ffffff] bg-[#40549D]">
  <div className="w-full md:w-1/2 mt-4 md:mt-0 md:order-2 my-8 md:px-6">
    <img src={aboutimg} alt="Image" className="max-w-full h-auto mx-auto" />
  </div>
  <div className="w-full md:w-1/2 md:order-1">
    <div className="px-4 md:px-8 py-4 md:mb-12">
      <h2 className="text-2xl font-semibold mb-6 text-center md:text-left">
        Découvrez Notre Agence Immobilière et Notre Engagement envers Votre Projet Immobilier
      </h2>
      <p className="text-lg mb-6 text-justify md:text-left md:grid md:grid-cols-[1fr 1fr] px-4">
  Bienvenue chez Diar Al Ghalia, l'agence immobilière de référence à Casablanca, dédiée à vous offrir des services immobiliers exceptionnels avec un engagement envers l'excellence et la satisfaction client.
</p>

      <div className="flex justify-center md:justify-start">
        <Link to="/aboutus">
          <button className="transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#40549D] hover:text-white mx-6 py-2 px-4 bg-[#ffffff] text-[#40549D] border border-[#40549D] rounded-md shadow-md hover:shadow-lg flex items-center space-x-2">
            <span>En savoir plus</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none" className="text-[#40549D] hover:text-white">
              <path d="M10.5 8.5H23.5M23.5 8.5V21.5M23.5 8.5L8.5 23.5" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </Link>
      </div>
    </div>
  </div>
</div>



<div className='inline flex items-center justify-center'>
      <hr></hr>
      <h3>Nos Appartement</h3>
    </div>
   
    <div className="flex space-x-4 mb-4 items-center justify-center cursor-pointer">

        {options.map((option) => (
          <h1
          key={option}
          className={`px-4 py-2 ${selectedOption === option ? 'text-[#40549D] border-b-2 border-[#40549D]' : 'text-gray-700'} hover:text-[#40549D] focus:text-[#40549D] focus:outline-none`}
          onClick={() => handleOptionChange(option)}
        >
          {option === 'appartements' ? 'Appartements' : 'Garages'}
        </h1>
        ))}
      </div>


      <div>
  {selectedOption === 'appartements' ? (
    <div className="" id="proposition">
      <h2 className="text-3xl font-semibold mb-6 text-center text-[#40549D]">Explorez Nos Appartements</h2>
      <Carousel responsive={responsive} swipeable={false} className="z-30 cursor-pointer">
        {propertyData.map((property, index) => (
          <div key={index} className="card my-6 mx-3 text-center cursor-pointer border rounded-lg overflow-hidden hover:shadow-lg transition duration-300 transform hover:-translate-y-2">
            <img className="product--image w-full h-48 object-cover rounded-t-lg" src={property.imageSrc} alt={property.title} />
            <div className="bg-white p-4 flex flex-col justify-between rounded-b-lg">
              <div>
                <h2 className="text-xl font-semibold text-[#40549D] mb-2">{property.title}</h2>
              </div>
             
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  ) : (
    <div className="text-center">
      <h2 className="text-3xl font-semibold mb-6 text-[#40549D]">Explorez Nos Garages</h2>
      <Carousel responsive={responsive} className="z-30">
      {garages.map((property, index) => (
          <div key={index} className="card my-6 mx-3 text-center cursor-pointer border rounded-lg overflow-hidden hover:shadow-lg transition duration-300 transform hover:-translate-y-2">
            <img className="product--image w-full h-48 object-cover rounded-t-lg" src={property.image} alt={property.title} />
            <div className="bg-white p-4 flex flex-col justify-between rounded-b-lg">
              <div>
                <h2 className="text-xl font-semibold text-[#40549D] mb-2">{property.title}</h2>
              </div>
             
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )}
</div>


<div className='inline flex items-center justify-center  '>
      <hr></hr>
      <h3 className='text-[#40549D]'>Nos valeurs</h3>
    </div>

<Valeurs />

<div className=" p-6 py-4 bg-cover bg-center h-[500px] flex items-center justify-center" style={{ backgroundImage: `url(${cta})` }}>
  <div className="w-full md:w-472 mx-auto flex items-center justify-center bg-white shadow-md rounded-md md:px-6">
    <div className="w-full md:w-675 h-262 p-6 md:p-16">
      <h2 className="text-center font-bold text-2xl text-[#08110C] font-plus_jakarta_sans px-4">
        Êtes-vous prêt à trouver votre maison idéale ?
      </h2>
      <p className="text-gray-700 text-center font-medium text-base leading-normal font-plus-jakarta-sans">
        Votre satisfaction est notre priorité. nous nous engageons à vous offrir une expérience immobilière exceptionnelle.
      </p>
      <div className="flex justify-center mt-4 md:mt-6">
        <Link to="/aboutus">
          <button className="transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#40549D] hover:text-[#ffffff] mx-6 py-2 px-4 bg-[#ffffff] text-[#40549D] border border-[#40549D] rounded-md shadow-md hover:shadow-lg flex items-center space-x-2">
            <span>En savoir plus</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" fill="none" className="text-[#40549D] hover:text-white">
              <path d="M10.5 8.5H23.5M23.5 8.5V21.5M23.5 8.5L8.5 23.5" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </Link>
      </div>
    </div>
  </div>
</div>






    <Footer />
</> 
  );
}

export default HomePage;
