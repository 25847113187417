import React from 'react';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Component/Navbar';
import HomePage from './Component/Home';
import Proposition from './Component/Proposition';
import Valeurs from './Component/Valeurs';
import Footer from './Component/Footer';
import BackToTopButton from './Component/Backtop';
import AboutUs from './Component/AboutUs';
import ContactUs from './Component/Contactus';
import Galerie from './Component/Galerie';
import EnCours from './Component/EnCours';

 

function App() {
  
  return (
    <div className="App">
       <BrowserRouter>
     
 
      <Routes>
        <Route
       path='/'
          
          element={
            <div className="animate-rotate-in">
              <Navbar />
              <HomePage />
            </div>
          }
        />
        <Route
          path="/proposition"
          element={
            <div className="animate-fade-scale">
              <Navbar />
              <Proposition />
            </div>
          }
        />
          <Route
          path="/valeurs"
          element={
            <div className="animate-fade-scale">
              <Navbar />
              <Valeurs />
              <Footer />
            </div>
          }
        />
           <Route
          path="/galerie"
          element={
            <div className="animate-fade-scale">
              <Navbar />
              <Galerie />
              <Footer />
            </div>
          }
        />
        <Route
          path="/aboutus"
          element={
            <div className="animate-bounce-in-top">
              <Navbar />

              <AboutUs />
            </div>
          }
        />
            
        <Route path="/contact" element={<div>
          <Navbar />
          <ContactUs/>
        </div>
        }/>

<Route path="/encours" element={<div>
          <Navbar />
          <EnCours />
          <Footer />

        </div>
        }/>


      </Routes>
      <BackToTopButton />
    </BrowserRouter>
      
      
      
          </div>
  );
}

export default App;
//  
//       <HomePage />
//       <Selection />
//       <Proposition />
//       <Valeurs />
//       <AboutUs />
//       <Footer />
//       <BackToTopButton />